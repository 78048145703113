import Helmet from 'react-helmet';
import React from 'react';

export const Seo = ({ title, description, keywords }) => {
  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
      ]}
    >
      <html lang="cs" />
    </Helmet>
  );
};
